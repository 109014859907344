#notfound {
    /*position: relative;*/
    height: 100vh;
}

#notfound .notfound-bg{
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(../../../assets/images-svg/notFound.jpg);
    background-size: cover;
}

#notfound .notfound-bg:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
}

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.notfound {
    max-width: 600px;
    width: 100%;
    text-align: center;
    padding: 30px;
    line-height: 1.4;
}
.notfound .notfound-404 {
    position: relative;
    height: 200px;
}
.notfound h1{
    font-family: 'Passion One', cursive;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 220px;
    margin: 0px;
    color: #222225;
    text-transform: uppercase;
}
.notfound h2 {
    direction: rtl;
    font-size: 26px;
    font-weight: 400;
    text-transform: uppercase;
    color: #222225;
    margin-top: 26px;
    margin-bottom: 20px;
}
#notfound .notfound:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    height: 600px;
    background-color: rgba(255, 255, 255, 0.7);
    /*-webkit-box-shadow: 0px 0px 0px 30px rgba(255, 255, 255, 0.7) inset;*/
    /*box-shadow: 0px 0px 0px 30px rgba(255, 255, 255, 0.7) inset;*/
    z-index: -1;
}
@media screen and (max-width: 576px){
    .notfound h1{
        font-size: 140px;
    }
}