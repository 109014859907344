
@font-face {
    font-family: iransans-bold;
    font-style: normal;
    font-weight: bold;
        src: url('../fonts/IranSans/IRANSans(FaNum)_Bold.ttf');
    }

    @font-face {
        font-family: iransans;
        font-style: normal;
        font-weight: 500;
        src: url('../fonts/IranSans/iransansfanum.ttf');
        src: url('../fonts/IranSans/IRANSans.ttf');
    }
