:root {
    --dark-green: #82A5AE;
    --main-back: #EEEEEE;
    --dv-orange: #FF6F00;
    --dark-blue: #0A446B;
    --title: #757575;
}

@font-face {
    font-family: iransans;
    src: url(../fonts/IranSans/IRANSans.ttf);
}

@font-face {
    font-family: iransansfanum;
    src: url(../fonts/IranSans/iransansfanum.ttf);
}

@font-face {
    font-family: "Font Awesome 5 Brands";
    src: url(../fonts/webfonts/fa-brands-400.ttf);
}

@font-face {
    font-family: "Font Awesome 5 Free Regular";
    src: url(../fonts/webfonts/fa-regular-400.ttf);
}

@font-face {
    font-family: "Font Awesome 5 Free Solid";
    src: url(../fonts/webfonts/fa-solid-900.ttf);
}

@font-face {
    font-family: "Font Awesome\ 5 Free";
    src: url(../fonts/webfonts/fa-light-300.ttf);
}

/*@media (prefers-color-scheme: dark) {*/
/*    h1 , h2 , h3 , h4 , h5 , h6 , p{*/
/*        color: #fff;*/
/*    }*/
/*}*/
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: iransans, 'poppins' !important;
    background: var(--main-back);
}

@media screen and (prefers-color-scheme: light) {
    body {
        background-color: var(--main-back);
        color: black;
    }
}

h1 {
    font-family: iransans !important;
    font-size: 33px;
}

h4 {
    font-size: 24px;
    font-family: iransans !important;
}

h5 {
    font-size: 18px;
    font-family: iransans !important;
}

h6 {
    font-size: 16px;
    font-family: iransans !important;
}

.rtl {
    direction: rtl;
}

.ltr {
    direction: ltr;
}

a {
    transition: .3s;
}

.stepper-container .stepper-item .stepper-title {
    transform: translate(40%, 5px) !important;
}

.international-input label {
    width: 70px !important;
}

.title-color {
    color: var(--title);
}

.md-form.md-outline label {
    left: 0.8rem;
    /*right: 1rem !important;*/
    padding: 0 1rem !important;
}

a:hover {
    text-decoration: none;
}

/*text colors*/


/*input*/

input:focus, textarea:focus, button:focus {
    outline: none;
    box-shadow: none;
}

input::placeholder, textarea::placeholder {
    color: var(--dark-green);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/*Toast*/
.Toastify__toast-body {
    text-align: right;
    direction: rtl;
    font-family: iransans;
    padding-right: .5rem;
}

/****************/
.stepper-item-outer {
    cursor: auto !important;
}

.dv-h-130 {
    height: 130%;
}

.dv-aral-logo {
    width: 20%;
}

.dv-line-h30 {
    line-height: 30px;
}

.dv-flex-btn {
    width: 154px;
    height: 55px;
    color: var(--dv-orange);
    border: 1px solid var(--dv-orange);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.7s;
}

.dv-flex-btn:hover {
    background: var(--dv-orange);
    color: #fff;
    transform: translateY(-5px);
}

.dv-circle {
    width: 45px;
    height: 45px;
    color: var(--dv-orange);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    border: 1px solid var(--dv-orange);
    border-radius: 50%;
}

.dv-circle .fa-pen, .dv-active-step-title {
    color: var(--dv-orange);
}

.dv-circle-deactive {
    width: 45px;
    height: 45px;
    border: 1px dotted var(--dark-blue);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.dv-md-inp {
    background: #fff !important;
    border: 1px solid #fff !important;
    border-radius: 30px !important;
    padding: 15px;
}

/*Next And Prev Btn */
.dv-prev-btn {
    margin: 0 0.5rem;
    width: 40px;
    height: 40px;
    border: 1px solid var(--dark-blue);
    color: var(--dark-blue);
    cursor: pointer;
    transition: 0.7s;
}

.dv-next-btn:hover, .dv-prev-btn:hover {
    background: var(--dv-orange);
    color: #fff;
    border-color: var(--dv-orange);
}

.dv-next-btn, .dv-next-btn-last {
    margin: 0 0.5rem;
    width: 48px;
    height: 48px;
    border: 1px solid var(--dark-blue);
    color: var(--dark-blue);
    cursor: pointer;
    transition: 0.7s;
}

.dv-prev-btn-first {
    margin: 0 0.5rem;
    width: 40px;
    height: 40px;
    border: 1px solid #747474;
    color: #747474;
    cursor: unset;
}

.stepper-container .stepper-item .stepper-title-active {
    font-family: iransans !important;
    color: var(--dv-orange) !important;
}

.stepper-item-outer:nth-of-type(odd) {
    border: 1px dotted var(--dark-blue) !important;
    width: 45px !important;
    height: 45px !important;
    color: var(--dark-blue) !important;
}

.stepper-item-outer:nth-of-type(odd) .stepper-item-inner-future {
    color: var(--dark-blue) !important;
}

.stepper-item-outer:nth-of-type(even) {
    border: none !important;
    width: 2.5rem !important;
}

.stepper-item-inner-active {
    border: 1px dotted var(--dv-orange) !important;
    background: transparent !important;
    width: 45px !important;
    height: 45px !important;
    color: #fff !important;
    position: absolute;
}

.stepper-item-inner-future {
    background: transparent !important;
}

.stepper-item-inner-completed {
    background: var(--dark-blue) !important;
    width: 45px !important;
    height: 45px !important;
    color: #fff !important;
    position: absolute;
}

.stepper-title {
    display: none !important;
}

.stepper-title-active {
    display: block !important;
}

.stepper-item-inner-active:before {
    font-family: 'Font Awesome 5 Free Regular';
    content: "\f040";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
    color: var(--dv-orange);
    background: var(--main-back);
    font-size: 20px;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 8px;
    top: 8px;
}

input {
    direction: rtl;
}

.md-form label {
    right: 0 !important;
}

.stepper-item-inner-active:after {
    content: '';
}

.stepper-item-inner-completed:before {
    font-family: 'Font Awesome 5 Free Regular';
    content: "\f00c";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
    background: var(--dark-blue);
    font-size: 20px;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 8px;
    top: 8px;
}

.stepper-container {
    justify-content: end !important;
}

.step-logo {
    width: 7.6rem;
}

/********* Forms Style ************/
.rj-form {
    background: #fff !important;
    border-radius: 2rem !important;
    /*padding-right: 1.5rem !important;*/
    /*padding-left: 1.5rem !important;*/
    border: none !important;
    height: 2.7rem !important;
    box-shadow: none !important;
    padding: 1.5rem !important;
    width: 100%;
}

.rj-form:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.rj-form ~ label {
    margin: auto 2rem;
    background: transparent !important;
}

.dv-form-inline {
    display: flex !important;
    justify-content: end;
}

.dv-form-inline .form-check {
    background: #fff;
    padding: 1rem 1rem;
    width: auto;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
}

/*.rj_form{*/
/*    padding: 1rem 0.5rem !important;*/
/*}*/

.dv-form-inline .form-check-input {
    position: relative;
    padding: 0 !important;
    width: 1.4rem;
    height: 1.4rem;
}

.dv-form-inline .form-check-input:checked {
    background: var(--dv-orange);
    border-color: var(--dv-orange);
    box-shadow: none !important;
}

.dv-form-inline .form-check-input:checked:before {
    font-family: 'Font Awesome 5 Free Regular';
    content: "\f00c";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
    font-size: 15px;
    width: 20px;
    color: #fff;
    height: 20px;
    position: absolute;
    right: -2px;
    top: 0;
}

.dv-form-inline .form-check .form-check-input {
    margin: 0 0 0 0.3rem !important;
}

.dv-input-group .dv-input-group-text {
    height: 100%;
    background: #fff;
    border: none;
    border-radius: 0 2rem 2rem 0;
    padding: 0.7rem 1.2rem;
    font-size: 1.5rem;
}

.dv-input-group .form-control {
    border-radius: 2rem 0 0 2rem !important;
    border: none;
    box-shadow: none;
    outline: none;
    padding: 1rem 0.5rem;
}

.dv-input-group .form-control:focus .dv-input-group-text {
    color: var(--dv-orange);
}

.md-form label.active {
    color: var(--dv-orange) !important;
}

.md-form {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

.dv-number-of-step {
    background: #fff !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.dv-card-back {
    background: #fff;
    width: 100%;
    height: 153px;
    border-radius: 1rem;
    color: var(--dark-blue);
}

.dv-card-back .dv-icon-card {
    font-size: 30px;
}

.custom-radios div {
    display: inline-block;
}

.step-2-input {
    display: none;
}

.step-2-input + label {
    color: #333;
    font-family: Arial, sans-serif;
    font-size: 14px;
    width: 100%;
}

.step-2-input + label .dv-card-back {
    display: inline-block;
    /*width: 154px;*/
    width: 100%;
    min-height: 150px;

    /*height: 153px;*/
    /*margin: 0.5rem;*/
    vertical-align: middle;
    cursor: pointer;
    /*box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);*/
    /*background-repeat: no-repeat;*/
    /*background-position: center;*/
    text-align: center;
    line-height: 44px;
}

.step-2-input + label .dv-card-back {
    background: #fff;
    transition: all .3s ease;
}

.step-2-input:checked + label .dv-card-back {
    background-color: var(--dark-blue);
    color: #fff;
}

.dv-textarea {
    background: #fff !important;
    border-radius: 1rem !important;
    border: 1px solid #fff !important;
    width: 100%;
}

.dv-textarea-parent label.active {
    background: transparent !important;
}

.step-2-input-bool {

}

.step-2-input-bool + label .dv-card-back {
    width: 69px !important;
    min-height: 50px !important;
    height: 50px !important;
}

.step-2-input-bool + label {
    width: 80px;
}

.step-2-input-bool:checked + label .dv-card-back {
    background: var(--dv-orange);
}

.rj-radio-step4 {
    display: flex;
    justify-content: space-between !important;
    padding-left: 2rem !important;
    padding-right: 3rem !important;
}

.dv-parent-label-input {
    width: 100%;
    background: #fff;
    height: 100%;
    border-radius: 2rem;
    padding: 0 2rem;
    display: flex;
    align-items: center;

}

.dv-control-label {
    position: absolute;
    width: 100%;
    padding-left: 3rem;
}


/* Hide the browser's default checkbox */
.dv-checkbox-step4 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.dv-checkbox-step4 .checkmark {
    position: absolute;
    top: 1rem;
    right: 2rem;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid #ccc;
    background: transparent;
}

/* When the checkbox is checked, add a blue background */
.dv-checkbox-step4 input:checked ~ .checkmark {
    background-color: var(--dv-orange);
    border: 1px solid var(--dv-orange);
}

/* Create the checkmark/indicator (hidden when not checked) */
.dv-checkbox-step4 .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.dv-checkbox-step4 input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.dv-checkbox-step4 .checkmark:after {
    left: 0.4rem;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.dv-step5-radio {
    height: 180px;
}

.dv-step5-radio label {
    height: 100%;
}

.custom-file-input {
    color: transparent;
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-file-input::before {
    content: 'فایل ضمیمه خود را بکشید یا انتخاب کنید';
    color: black;
    background: transparent;
    width: 180px;
    border: 1px dashed #999;
    border-radius: 1rem;
    outline: none;
    white-space: initial;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-size: 1rem;
    padding-top: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    height: 130px;
}

.custom-file-input:hover::before {
    border-color: black;
}

.custom-file-input:active {
    outline: 0;
}

.custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #cbcbcb, #9a9a9a);
}

.dv-custom-icon {
    position: absolute;
    right: 5rem;
    top: 1.5rem;
    font-size: 1.5rem;
}

.dv-custom-main-height {
    height: 450px;
}

.dv-custom-btn {
    padding: 0.8rem 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2rem;
    background: #fff;
    border: 1px solid #fff;
    color: var(--dv-orange);
    font-size: 1.2rem;
    transition: 0.7s;
}

.dv-custom-btn:hover {
    transform: translateY(-5px);
    background: var(--dv-orange);
    color: #fff;
    border-color: var(--dv-orange);
}

.bg-link-parent {
    background: #fff;
    border-radius: 2rem;
    padding: 1rem 1rem 1rem 2rem;
}

.dv-link {
    color: var(--dark-blue);
}

.dv-left-icons {
    font-size: 1rem;
}

.dv-left-icons i {
    padding: 0 0.5rem;
}

.dv-btn-save2 {
    background: #fff;
    border-radius: 2rem;
    border: 1px solid var(--dv-orange);
    color: var(--dv-orange);
    padding: 0.5rem 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    margin: 0.5rem;
    transition: 0.7s;
}

.dv-btn-save2:hover {
    transform: translateY(-5px);
    border: 1px solid #fff;
    color: #fff;
    background: var(--dv-orange);
}

.dv-btn-close {
    margin: 0.5rem;
    border-radius: 2rem;
    border: 1px solid #fff;
    color: #fff;
    background: var(--dv-orange);
    padding: 0.5rem 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.dv-color-textarea {
    border: 1px solid #e7e7e7;
    text-align: right;
    direction: rtl;
    transition: 0.7s;
    padding: 1rem;
}

.dv-btn-close:hover {
    transform: translateY(-5px);
    background: #fff;
    border: 1px solid var(--dv-orange);
    color: var(--dv-orange);
}

.dv-modal-body {
    line-height: 2rem;
}

.modal-content {
    border-radius: 1rem !important;
}

.modal-header {
    border-bottom: none;
}

.modal-header .close {
    display: none;
}

.modal-footer {
    border-top: none;
}

.dv-last-btn {
    background: transparent;
    border-radius: 2rem;
    border: 1px solid var(--dv-orange);
    color: var(--dv-orange);
    padding: 0.8rem 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    transition: 0.7s;
}

.dv-last-btn:hover {
    transform: translateY(-5px);
    border: 1px solid #fff;
    color: #fff;
    background: var(--dv-orange);
}

.dv-border-stepper .stepper-item-outer:nth-of-type(even) {
    border: 0.5px dotted #707070 !important;
}

.dv-size-last {
    height: 400px;
    margin-top: 8rem
}

.dark_theme {
    background: #000;
    color: #fff;
    min-height: 100vh;
    height: 100%;
}

.dark_theme .stepper-item-inner-active:before {
    color: var(--dv-orange);
    background: #000;
}

.dark_theme .custom-checkbox h6 {
    color: var(--dark-blue);
}

.dark_theme .dv-number-of-step, .dark_theme .dv-left-icons, .dark_theme .form-check-label {
    color: var(--dark-blue) !important;
}

.dark_theme .custom-file-input::before {
    text-shadow: none !important;
    color: #fff !important;
}

body {
    overflow-x: hidden !important;
}

/****************/
.dv-width-input-welcome-parent label {
    left: 160px !important;
}

.dv-width-input-welcome {
    width: 300px;
}

@media screen and (max-width: 768px) {
    .dv-aral-logo {
        width: 50%;
    }

    .dv-custom-container {
        padding-right: 2rem;
        padding-left: 2rem;
    }

    h1 {
        font-size: 24px;
    }

    .dv-text-justify, .dv-custom-main-height p {
        text-align: justify;
    }

    .stepper-container {
        display: none;
    }

    .dv-form-inline .form-check {
        margin-right: 0;
    }

    .step-2-input + label {
        margin-bottom: 1.5rem;
    }

    .dv-radio-parent .form-check:first-child {
        /*margin-left: 0.5rem;*/
        margin-right: 0.5rem;
    }

    .dv-radio-parent .form-check:last-child {
        margin-left: 0.5rem;
        /*margin-right: 0.5rem;*/
    }

    .dv-custom-main-height {
        height: auto;
    }

    .dv-border-stepper {
        display: none !important;
    }

    .dv-size-last {
        margin-top: 15rem;
    }
}

.md-form label {
    left: 100%;
    width: 400px;
}

.md-form label.active {
    transform: translateY(-14px) scale(0.8);
    right: -4rem !important;
}

.modal-content {
    padding: 2rem;
}

.dv_radio_button_label input{
    accent-color : var(--dv-orange);
    background: #fff;
    width: 15px;
    height: 15px;
    margin:0 0.7em;
}

.dv_radio_button_label{
    display: flex;
    align-items: center;
    justify-content: center;
}